<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p
            class="large--title m-0"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            {{ $t("message.new_m") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.question_direction_list") }}
                </span>
                <el-form-item prop="title" class="label_mini">
                  <select-question-direction
                    :size="'small'"
                    :class="mode ? 'input__day' : 'input__night'"
                    :multiple="true"
                    :id="form.question_direction_ids"
                    v-model="form.question_direction_ids"
                  >
                  </select-question-direction>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.question_category_list") }}
                </span>
                <el-form-item prop="title" class="label_mini">
                  <select-question-categories
                    :size="'small'"
                    :class="mode ? 'input__day' : 'input__night'"
                    :multiple="true"
                    :id="form.question_category_ids"
                    v-model="form.question_category_ids"
                  >
                  </select-question-categories>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.what_for") }}
                </span>
                <el-form-item prop="what_for">
                  <el-select
                    class="d-block"
                    size="small"
                    v-model="form.what_for"
                    :placeholder="$t('message.what_for')"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-option
                      :label="'Ishga qabul qilish'"
                      :value="'recruiting'"
                    >
                    </el-option>
                    <el-option
                      :label="'Bilimini oshirish'"
                      :value="'increase_knowledge'"
                    >
                    </el-option>
                    <el-option :label="'Barchasi uchun'" :value="'for_all'">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.question_type_list") }}
                </span>
                <el-form-item prop="type">
                  <el-select
                    class="d-block"
                    size="small"
                    v-model="form.type"
                    :placeholder="$t('message.type')"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-option :label="'Chois'" :value="'chois'"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.media_type") }}
                </span>
                <el-form-item prop="media_type">
                  <el-select
                    class="d-block"
                    size="small"
                    v-model="form.media_type"
                    :placeholder="$t('message.media_type')"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-option :label="'Text'" :value="'text'"> </el-option>
                    <el-option :label="'Photo'" :value="'photo'"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.status") }}
                </span>
                <el-form-item prop="status">
                  <el-select
                    class="d-block"
                    size="small"
                    v-model="form.status"
                    :placeholder="$t('message.status')"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-option :label="$t('message.active')" :value="'active'">
                    </el-option>
                    <el-option
                      :label="$t('message.inactive')"
                      :value="'inactive'"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.time") }} ({{ $t("message.minut") }})
                </span>
                <el-form-item :prop="form.time">
                  <!-- <el-input
                            :placeholder="$t('message.time')"
                            v-model="form.time"
                            size="small"
                            round
                          ></el-input> -->
                  <!-- <el-input-number v-model="form.time" :min="1" :max="10" @change="handleChange" />
                           -->
                  <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                    :inputValue="form.time"
                    :placeholder="$t('message.time')"
                    v-model="form.time"
                    size="medium"
                    :type="'number'"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.branch") }}
                </span>
                <el-form-item prop="branch_id">
                  <select-branch
                    :company_id="form.company_id"
                    v-model="form.branch_id"
                    :id="form.branch_id"
                    :size="'medium'"
                  ></select-branch>
                </el-form-item>
              </el-col>
              <el-col
                :span="24"
                class="option-upload company_info"
                v-if="form.media_type == 'photo'"
              >
                <div id="app" class="my-upload">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-3"
                  >
                    {{ $t("message.logo") }}
                  </span>
                  <el-upload
                    class="upload-demo"
                    action="/"
                    :limit="1"
                    :on-change="createImageList"
                    :file-list="imageList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :class="mode ? '' : 'upload__mynight'"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </el-col>
              <el-col :span="24">
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("message.content") }}
                </span>
                <el-form-item prop="content" class="label_mini">
                  <el-input
                    type="textarea"
                    :rows="4"
                    :placeholder="$t('message.content')"
                    :inputValue="form.content"
                    v-model="form.content"
                    class="text-area-el"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <hr />
                <h4>Javoblar</h4>
              </el-col>
              <el-col
                :span="24"
                v-for="(option, index) in options"
                :key="option.key"
              >
                <span
                  :class="mode ? 'text__day2' : 'text__night2'"
                  class="input--label d-block mb-2"
                >
                  {{ $t("Javob: " + (index + 1)) }}
                </span>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <span
                      :class="mode ? 'text__day2' : 'text__night2'"
                      class="input--label d-block mb-2"
                    >
                      {{ $t("message.media_type") }}
                    </span>
                    <el-form-item prop="media_type">
                      <el-select
                        class="d-block"
                        size="small"
                        v-model="option['type']"
                        :placeholder="$t('message.media_type')"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                        <el-option :label="'Text'" :value="'text'"> </el-option>
                        <el-option :label="'Photo'" :value="'photo'">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="company_info option-upload">
                    <div
                      @click="getIndex(index)"
                      id="app"
                      class="my-upload"
                      v-if="option['type'] == 'photo'"
                    >
                      <span
                        :class="mode ? 'text__day2' : 'text__night2'"
                        class="input--label d-block mb-3"
                      >
                        {{ $t("message.option") }}
                      </span>
                      <el-upload
                        :on-change="createOptionImageList"
                        :file-list="option.option_images"
                        class="upload-demo"
                        action="#"
                        :limit="1"
                        list-type="picture-card"
                        :auto-upload="false"
                        :class="mode ? '' : 'upload__mynight'"
                      >
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </div>
                    <div v-else>
                      <span
                        :class="mode ? 'text__day2' : 'text__night2'"
                        class="input--label d-block mb-2"
                      >
                        {{ $t("message.content") }}
                      </span>
                      <!-- prop="content" -->
                      <el-form-item
                        :rules="{
                          required: true,
                          message: 'option can not be null',
                          trigger: 'blur'
                        }"
                        class="label_mini"
                      >
                        <el-input
                          type="textarea"
                          :rows="3"
                          :placeholder="$t('message.content')"
                          :inputValue="option.title"
                          v-model="option.title"
                          class="text-area-el"
                          :class="mode ? 'input__day' : 'input__night'"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <span
                      :class="mode ? 'text__day2' : 'text__night2'"
                      class="input--label d-block mb-2"
                    >
                      {{ $t("Point") }}
                    </span>
                    <!-- prop="point" -->
                    <el-form-item
                      :rules="{
                        required: true,
                        message: 'option can not be null',
                        trigger: 'blur'
                      }"
                      class="label_mini"
                    >
                      <crm-input
                        :class="mode ? 'input__day' : 'input__night'"
                        :inputValue="option.point"
                        :placeholder="$t('message.point')"
                        v-model="option.point"
                        size="medium"
                        :type="'number'"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer],
  name: "questionController",
  data() {
    return {
      options: [
        {
          key: 1,
          option_images: [],
          option_image: "",
          title: "",
          type: "text",
          point: 0
        },
        {
          key: 2,
          option_images: [],
          option_image: "",
          title: "",
          type: "text",
          point: 0
        },
        {
          key: 3,
          option_images: [],
          option_image: "",
          title: "",
          type: "text",
          point: 0
        },
        {
          key: 4,
          option_images: [],
          option_image: "",
          title: "",
          type: "text",
          point: 0
        }
      ],
      imageList: [],
      image: "",
      loadingButton: false,
      selectedLogoIndex: null
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "question/rules",
      model: "question/model",
      columns: "question/columns",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      save: "question/store"
    }),
    handleChange(value) {
      console.log(value);
    },
    createImageList(file) {
      this.form.image = file.raw;
      console.log(this.form.image, "image");
    },
    getIndex(index) {
      this.selectedLogoIndex = index;
    },
    createOptionImageList(file) {
      console.log(this.options[this.selectedLogoIndex]);
      console.log(file, "asdasd");
      this.options[this.selectedLogoIndex].option_image = file.raw;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    submit(close = true) {
      this.$refs["form"].validate(valid => {
        let formData = new FormData();
        for (const key in this.form) {
          if (key == "question_direction_ids") {
            for (const question_direction_key in this.form
              .question_direction_ids) {
              formData.append(
                `question_direction_ids[${question_direction_key}]`,
                this.form.question_direction_ids[question_direction_key]
              );
            }
          }
          if (key == "question_category_ids") {
            for (const question_category_key in this.form
              .question_category_ids) {
              formData.append(
                `question_category_ids[${question_category_key}]`,
                this.form.question_category_ids[question_category_key]
              );
            }
          }
          if (
            key != "question_direction_ids" &&
            key != "question_category_ids"
          ) {
            formData.append(key, this.form[key]);
          }
        }
        this.options.forEach((option, index) => {
          for (const option_key in option) {
            console.log(option_key, option[option_key], "option_key");
            if (option_key != "option_images") {
              formData.append(
                `options[${index}][${option_key}]`,
                option[option_key]
              );
            }
          }
        });
        console.log("formData", formData.get("options[0]"));

        if (valid) {
          this.loadingButton = true;
          this.save(formData)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
              this.form.content = null;
              this.form.media_type = null;
              this.form.status = null;
              this.form.time = null;
              this.form.image = null;
              this.form.type = null;
              (this.imageList = []), (this.form.what_for = null);
              this.form.question_category_ids = [];
              this.form.question_direction_ids = [];
              this.options = [
                {
                  key: 1,
                  option_images: [],
                  option_image: "",
                  title: "",
                  type: "text",
                  point: 0
                },
                {
                  key: 2,
                  option_images: [],
                  option_image: "",
                  title: "",
                  type: "text",
                  point: 0
                },
                {
                  key: 3,
                  option_images: [],
                  option_image: "",
                  title: "",
                  type: "text",
                  point: 0
                },
                {
                  key: 4,
                  option_images: [],
                  option_image: "",
                  title: "",
                  type: "text",
                  point: 0
                }
              ];
            })
            .catch(err => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeoption(item) {
      var index = this.dynamicValidateForm.options.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.options.splice(index, 1);
      }
    },
    addoption() {
      this.dynamicValidateForm.options.push({
        key: Date.now(),
        value: ""
      });
    }
  }
};
</script>
<style lang="scss">
.option-upload {
  .upload-demo {
    position: relative;
    min-height: 150px;
    .el-upload-list__item.is-ready {
      position: absolute !important;
      z-index: 1 !important;
    }
    > .el-upload {
      position: absolute !important;
      z-index: 0 !important;
    }
  }
}
</style>
